import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import customer from "../../assets/img/onur-afsar.png"
import OtherCaseStudy from "../../components/other-case"

import detail1 from "../../assets/img/matbuu-detail1.png"
import detail2 from "../../assets/img/matbuu-detail2.png"
import detail3 from "../../assets/img/matbuu-detail3.png"
import detail4 from "../../assets/img/matbuu-detail4.png"
import detail5 from "../../assets/img/matbuu-detail5.png"
import detail6 from "../../assets/img/matbuu-detail6.png"
import detail7 from "../../assets/img/matbuu-detail7.png"

import eelbeyglu from "../../assets/img/teams/11.jpg"
import serife from "../../assets/img/teams/20.jpg"
import tugce from "../../assets/img/teams/03.png"
import simay from "../../assets/img/teams/08.jpg"
import onay from "../../assets/img/teams/23.jpg"

const ReferancesDetail = () => (
  <Layout>
    <SEO title="Matbuu Success Story"
    description="Click to see the success of the digital marketing service we provided to Matbuu!"

    />
    <div className="referances-detail py60 container">
      <div className="col-md-6 detail-left">
        <b>SUCCESS STORIES</b>
        <h1>Matbuu</h1>
        <p>
          We provide SEO (Search Engine Optimization), advertisement management
          (Google Adwords) and interface design services for AFB Group's
          Matbuu.com, which we agreed in November 2015. As a result of our work,
          Matbuu.com has achieved 80% growth rate in the last 3 years and has
          significantly increased its awareness.
        </p>
        <h2>Problems</h2>
        <ul>
          <li>
            &bull; Low conversion rates observed due to the fact that Matbuu.com
            website, which has a challenging and detailed ordering system, does
            not address the end user with the right design,
          </li>
          <li>&bull; Slow growth of turnover due to conversion rate,</li>
          <li>
            &bull; Low traffic gained despite the high organic traffic volume.
          </li>
        </ul>

        <h2>Solution</h2>
        <p>
          First of all, we determined the points that users have difficulty in
          order size in printing processes with Google Analytics. We have
          created a new UI and UX design to make these areas understandable.
        </p>
        <p>
          We realized content production and backlink activities suitable for
          keyword competition for organic traffic.
        </p>
        <p>
          Thanks to the digital media advertisements we conducted together with
          TV advertisements to strengthen the brand image, we significantly
          increased awareness.
        </p>
      </div>
      <div className="col-md-6 referances-boxs">
        <div className="boxs">
          <div className="box">
            <span>%172</span>
            <p>Increase in turnover within 2 years</p>
          </div>
          <div className="box">
            <span>%150</span>
            <p>Organic traffic increase for 2 years .</p>
          </div>
          <div className="box">
            <span>%24</span>
            <p>Conversion Rate increase</p>
          </div>
          <div className="box">
            <span>3x</span>
            <p>Growth in brand awareness in 4 years</p>
          </div>
        </div>
        <div className="provided">
          <h3>Services Provided</h3>
          <div className="provided-box">
            <div className="box">
              <Link to="/email-marketing/"></Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 86 86"
              >
                <g
                  id="Icons-_-Communication-_-Mail-heart"
                  data-name="Icons-/-Communication-/-Mail-heart"
                  transform="translate(0.169 -0.307)"
                >
                  <rect
                    id="bound"
                    width="86"
                    height="86"
                    transform="translate(-0.169 0.307)"
                    fill="none"
                  />
                  <path
                    id="Combined-Shape"
                    d="M8.569,2H51.4a3.569,3.569,0,0,1,3.569,3.569V41.262A3.569,3.569,0,0,1,51.4,44.831H8.569A3.569,3.569,0,0,1,5,41.262V5.569A3.569,3.569,0,0,1,8.569,2Zm27.84,7.138c-2.3,0-4.985,2.605-6.425,4.283-1.439-1.678-4.127-4.283-6.425-4.283-4.068,0-6.425,3.173-6.425,7.21q0,6.71,12.849,14.2,12.849-7.5,12.849-13.92C42.834,12.6,40.477,9.138,36.409,9.138Z"
                    transform="translate(13.236 5.138)"
                    fill="#111"
                    fill-rule="evenodd"
                    opacity="0.3"
                  />
                  <path
                    id="Combined-Shape-2"
                    data-name="Combined-Shape"
                    d="M5.829,6.816,35.123,27.972,64.417,6.816a1.785,1.785,0,0,1,2.829,1.447V44.034a7.138,7.138,0,0,1-7.138,7.138H10.138A7.138,7.138,0,0,1,3,44.034V8.263A1.785,1.785,0,0,1,5.829,6.816Z"
                    transform="translate(8.097 16.643)"
                    fill="#111"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              Email Marketing
            </div>
            <div className="box">
              <Link to="/ui-ux-design/"></Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 76 76"
              >
                <g
                  id="Icons-_-Design-_-Sketch"
                  data-name="Icons-/-Design-/-Sketch"
                  transform="translate(0.015 -0.307)"
                >
                  <rect
                    id="bound"
                    width="76"
                    height="76"
                    transform="translate(-0.015 0.307)"
                    fill="none"
                  />
                  <path
                    id="Path-48"
                    d="M13.728,3H58.276L71,18.91H1Z"
                    transform="translate(2.182 6.546)"
                    fill-rule="evenodd"
                    opacity="0.3"
                  />
                  <path
                    id="Path-48-Copy"
                    d="M71,8,36,46.184,1,8Z"
                    transform="translate(2.182 17.456)"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              UI&UX Design
            </div>
            <div className="box">
              <Link to="/search-engine-optimization/"></Link>
              <svg
                style={{ marginLeft: -7 }}
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 79 80"
              >
                <g
                  id="Icons-_-Shopping-_-Chart-line_1"
                  data-name="Icons-/-Shopping-/-Chart-line#1"
                  transform="translate(-0.462 0.373)"
                >
                  <rect
                    id="bound"
                    width="79"
                    height="80"
                    transform="translate(0.462 -0.373)"
                    fill="none"
                  />
                  <path
                    id="Path-95"
                    d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z"
                    transform="translate(6.895 7.094)"
                    fill="#000000"
                  />
                  <path
                    id="Path-97"
                    d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z"
                    transform="translate(16.088 15.138)"
                    fill="#000000"
                    opacity="0.3"
                  />
                </g>
              </svg>
              SEO
            </div>
            <div className="box">
              <Link to="/digital-ads/"></Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 76 76"
              >
                <g
                  id="Icons-_-Shopping-_-Cart_1"
                  data-name="Icons-/-Shopping-/-Cart#1"
                  transform="translate(0.452 0.373)"
                >
                  <rect
                    id="bound"
                    width="76"
                    height="76"
                    transform="translate(-0.452 -0.373)"
                    fill="none"
                  />
                  <path
                    id="Combined-Shape"
                    d="M52.453,31.233l-2.2,13.227-.008.046a3.161,3.161,0,0,1-3.675,2.544L10.63,40.517a3.161,3.161,0,0,1-2.474-2.241L2.389,18.088a3.161,3.161,0,0,1,3.039-4.029H48.813l1.938-8.306a3.729,3.729,0,0,1,3.632-2.882h6.527a3.729,3.729,0,1,1,0,7.459H57.342Z"
                    transform="translate(4.899 6.203)"
                    fill-rule="evenodd"
                    opacity="0.3"
                  />
                  <path
                    id="Combined-Shape-2"
                    data-name="Combined-Shape"
                    d="M9.741,27.482a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,9.741,27.482Zm28.447,0a4.741,4.741,0,1,1,4.741-4.741A4.741,4.741,0,0,1,38.188,27.482Z"
                    transform="translate(10.804 38.894)"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
              Google Ads
            </div>
          </div>
        </div>
        <div className="project-details">
          <h3>Impression</h3>
          <div className="project-details_list">
            <ul>
              <li>Brand Manager:</li>
              <li>Digital Marketing Strategist:</li>
              <li>Google Ads:</li>
              <li>SEO:</li>
              <li>Website Development:</li>
            </ul>
            <ul>
              <li>
                <img src={simay} alt="Simay Akkurt" />
                Simay Akkurt
              </li>
              <li>
                <img src={eelbeyglu} alt="Alaattin Emre Elbeyoğlu" />
                Emre Elbeyoğlu
              </li>
              <li>
                <img src={serife} alt="Şerife Aytekin" />
                Şerife Aytekin
              </li>
              <li>
                <img src={tugce} alt="Tuğçe Gör" />
                Tuğçe Gör
              </li>
              <li>
                <img src={onay} alt="Barış Onay" />
                Barış Onay
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="testimonial-customer" id="one">
        <p>
          “The Flatart team plays a big role in making us stand out among the
          companies that provide online printing services. We would like to
          thank the Flatart team for SEO, which helped us bring our products
          together with our customers.”
        </p>
        <div className="author">
          <div>
            <img src={customer} alt="Onur Afşar"></img>
          </div>
          <div className="author-name">
            Onur Afşar
            <br />
            <span>CEO</span>
          </div>
        </div>
      </div>

      <div className="site-preview" style={{ marginBottom: 0 }}>
        <h2 className="head-case">
          Detailed View of the Printed Success Story
        </h2>
        <p style={{ marginBottom: 0 }}>
          We formulated the strategy necessary for Matbuu.com to obtain a
          quality traffic.
        </p>
      </div>

      <div className="customer-content">
        <img className="single" style={{ borderRadius: 10 }} src={detail1} />
        <h2>650% increase in organic traffic for target words in 4 years.</h2>
        <img className="single" style={{ borderRadius: 10 }} src={detail2} />

        <p>
          With On-Page SEO and Off-Page SEO requirements, we were able to take
          the first 3 places in highly competitive words in organic traffic. We
          achieved backlinks with our content improvements and website
          optimizations.
        </p>
        <h2>Perpetrator Unsolved: Business Card Sponsor Sponsor Matbuu.com</h2>
        <img className="single" style={{ borderRadius: 10 }} src={detail3} />

        <p>
          We became the supporter of the project by distributing free business
          cards in the micro site we prepared for the “Unsuccessful Unsuccessful
          Kick” project, which is the kindness movement of the idea workshop.
          While the project broke the records of sharing on social media and
          blogs, we provided a serious authority in the focus of the word
          “business card” with the backlinks we obtained.
        </p>
        <h2>
          An interface design experience that will clearly explain itself to the
          end user.
        </h2>

        <p>
          For the interface design, we watched the user movements for 2 months
          via Google Analytics and Fullstory. We have created solutions for
          every difficulties experienced and every screen that is not
          understood.
        </p>
        <img style={{ width: "100%", margin: "15px auto" }} src={detail4} />
        <h2>
          “Being Pessimistic Be Corporate” Campaign Launch and Digital
          Communication Studies
        </h2>
        <img style={{ width: "50%", margin: "15px auto" }} src={detail5} />

        <p>
          Digital communication activities were designed to announce the
          campaign launched for tradesmen, corporate firms and new ventures to
          the target audience.
        </p>
        <div className="row-img">
          <img style={{ borderRadius: 20, height: "auto" }} src={detail6} />
          <img style={{ borderRadius: 20, height: "auto" }} src={detail7} />
        </div>
      </div>
    </div>

    <OtherCaseStudy />

    <Checkup />
  </Layout>
)

export default ReferancesDetail
